import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './App.css';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Typography } from "@mui/material";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { styled } from "@mui/material/styles";
import "./App.css";
import logo from "./eumlet_white.png";

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }


const CustomDialogTitle = styled(DialogTitle)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
});

const Logo = styled('img')({
  height: '50px',
  marginBottom: '10px',
});

// #1f2935 for modal
const DialogContentText = styled('p')({
  textAlign: 'justify',
  color: 'whitesmoke',
  fontSize: '18px',
  fontWeight: '300'
});

const Dialog = styled('div')({

});



  const EumletLink: React.FC = () => {
    const [linkResp, setlinkResp] = useState("")
    let query = useQuery();
    let redirect_url = query.get("redirect_url");
    let nonce = query.get("nonce");
    let customer_id = query.get("customer_id");
    let corporate = query.get("corporate")
    let end_user_id = query.get("end_user_id");  
    const [loading, setLoading] = useState(false);

    console.log(corporate)
    const myCallback = (data: any) => {
        setlinkResp(data.status)
        console.log(data)
        if (data.status == "ERROR") {
            window.location.href = `${redirect_url}?link_response=${data.status}&nonce=${nonce}`;
        }
        if (data.status == "CANCELLED") {
            window.location.href = `${redirect_url}?link_response=${data.status}&nonce=${nonce}`;
        }
        if (data.status == "SUCCESS") {
            window.location.href = `${redirect_url}?link_response=${data.status}&nonce=${nonce}`;
        }
      };
     
  const handleScriptReady = () => {
    if ((window as any).Lean) {
      if (corporate == "true") {
        (window as any).Lean.connect({

          app_token: ""+process.env.REACT_APP_APP_TOKEN_2_DEV,
          permissions: ['identity', 'accounts', 'transactions', 'balance', 'payments'],
          customer_id: customer_id,
          end_user_id: end_user_id,
          callback: myCallback,
      });
      }
      else {
        (window as any).Lean.connect({
          app_token: ""+process.env.REACT_APP_APP_TOKEN_DEV,
          permissions: ['identity', 'accounts', 'transactions', 'balance', 'payments'],
          customer_id: customer_id,
          callback: myCallback,
      });
      }
    }
  };

  // useEffect(() => {

  // }, []);

  const handleAccept = () => {
    setLoading(true);
    const script = document.createElement('script');
    script.src = 'https://cdn.leantech.me/link/sdk/web/latest/Lean.min.js';
    script.async = true;
    script.onload = () => {
      handleScriptReady();
    };
    document.body.appendChild(script);

    // Clean up the script when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  };

  const handleDecline = () => {
    // Redirects to redirect_url parameter
    const authorization_code = "rejected";
    // window.location.href = `${redirect_url}?authorization_code=${authorization_code}`;
    // var url = window.location.href.split('?')[0];
    // console.log(url);
    window.location.href = 'http://localhost:3000/null?link_response=ERROR&nonce=null';
  };

  const loggedInView = (
    <>
      <div className="flex-container" style={loading ? { display: 'none' } : { display: 'block' }}>
        <div>
          {/* <Dialog> */}
          {/* <Dialog open={openDialog} onClose={handleDecline}> handleDecline here dont make any sense */}
          <CustomDialogTitle>
            <Logo src={logo} alt="logo" />
            <Typography variant="h5">{"Personal Information Authorization"}</Typography>
          </CustomDialogTitle>
          <DialogContent>
            <DialogContentText>
              In order to deliver a personalized, efficient, and secure experience, our platform needs access to certain
              aspects of your data. We want to assure you that we respect your privacy and are committed to handling
              your data responsibly and in accordance with all applicable data protection laws. By agreeing to this,
              you're allowing us to use your data for the sole purpose of enhancing your user experience on our platform.
              You have the right to withdraw this consent at any time. Please review our privacy policy
              for more information on how we use and protect your data.
              <br />
              <br />
              Do you agree to allow us to access your data?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDecline} color="primary" variant="outlined">
              Decline
            </Button>
            <Button onClick={handleAccept} color="primary" variant="contained">
              Agree
            </Button>
          </DialogActions>
          {/* </Dialog> */}
          {/* </Dialog> */}
        </div>
      </div>
      {loading ? (
        <div className="loader">
          {/* <img src={loader} alt="Loading..." /> */}
        </div>
      ) : (
        <div className="container">
          {/* ... */}
        </div>
      )}
    </>
  );



  return (
    <>
      <div id="lean-link"></div>
      <div className="grid">{loggedInView}</div>
    </>
  );
};

export default EumletLink;
