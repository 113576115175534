import React from 'react';
import logo from './logo.svg';
import './App.css';
import EumletLink from './EumletLink';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LinkBen from './LinkBen';
import Verify from './Verify';
import LinkPay from './LinkPay';
import LinkAuth from './LinkAuth';

function App() {
     return (
          <div className="App">
               <Router>
                    <Routes>
                         <Route path="/" Component={Verify} />
                         <Route path="/entity" Component={EumletLink} />
                         <Route path="/beneficiary" Component={LinkBen} />
                         <Route path="/pay" Component={LinkPay} />
                         <Route path="/authorize" Component={LinkAuth} />

                    </Routes>
               </Router>
          </div>
     );
}

export default App;
